import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Form,
  Input,
  Typography,
  Select,
  DatePicker,
  Checkbox,
  Switch,
  Button,
} from 'antd';
// Api
import {
  CREATE_CAMPAIGN_EXAMPLE_FILES,
  UPDATE_CAMPAIGN,
} from 'api/campaignV2/mutations';
import { GET_HASHTAGS } from 'api/hashtags/queries';
import { GET_COUNTRY_LIST } from 'api/users/queries';
import { SEARCH_CAMPAIGN_STORES } from 'api/campaignV2/queries';
// Hooks
import { useGetSports } from 'hooks';
// Helpers
import {
  FOLLOWING_OPTIONS_MAX,
  FOLLOWING_OPTIONS_MIN,
  GENDER_OPTIONS,
  isUsOrCa,
  MEDIA_TYPES,
  PRODUCT_TYPES,
  uploadImages,
} from 'helpers/campaignEditModal';
// Constants
import { onlyDateTimeFormat } from 'constants/global';
// Types
import {
  GetCampaignsV2_getCampaigns_entities,
  GetCampaignsV2_getCampaigns_entities_activities_SocialActivity,
  GetCampaignsV2_getCampaigns_entities_search,
} from 'api/campaignV2/types/GetCampaignsV2';
import {
  UpdateCampaign,
  UpdateCampaignVariables,
} from 'api/campaignV2/types/UpdateCampaign';
import {
  CampaignExampleFileInput,
  CampaignLocationInput,
  CampaignMediaType,
  CampaignSmPlatform,
  CampaignTaskType,
  CreateCampaignDetailsInput,
  CreateCampaignTaskInput,
  SearchCampaignCriteriaInput,
  SortDirection,
  StoreGender,
  UpdateCampaignInput,
  UserRole,
} from 'api/graphql-global-types';
import {
  GetHashtags,
  GetHashtagsVariables,
} from 'api/hashtags/types/GetHashtags';
import {
  CreateCampaignExampleFiles,
  CreateCampaignExampleFilesVariables,
} from 'api/campaignV2/types/CreateCampaignExampleFiles';
import { GetCountryList } from 'api/users/types/GetCountryList';
import { RangePickerProps } from 'antd/lib/date-picker';
import {
  SearchCampaignStores,
  SearchCampaignStoresVariables,
} from 'api/campaignV2/types/SearchCampaignStores';
// Ui
import UploadMultipleImages, {
  UploadImage,
} from 'ui/UploadMultipleImages/UploadMultipleImages';
import { errorNotification, successNotification } from 'ui/Notification';
// Components
import TalentList from '../TalentList/TalentList';
// Style
import styles from './SocialCampaignsEditModal.module.scss';

const { Option } = Select;
const { Text } = Typography;
const { TextArea } = Input;

type FormValues = {
  budget: number;
  direction?: SortDirection | null;
  flatFeePerStore: number;
  genders?: StoreGender[] | null;
  locations?: CampaignLocationInput[] | null;
  maxAge?: number | null;
  minAge?: number | null;
  smPlatforms: CampaignSmPlatform[];
  followingFrom: number | null;
  followingTo: number | null;
  sports: string[];
  avoid?: string | null;
  brandName: string;
  dueDate: any;
  encouraged?: string | null;
  exampleFiles?: CampaignExampleFileInput[] | null;
  hashtags?: string[] | null;
  instructions: string;
  mediaType?: CampaignMediaType[] | null;
  name: string;
  physicalProductType?: string | null;
  profileMentions?: string | null;
  promoCodes?: string | null;
  promoUrls?: string | null;
  quantityInstructions?: string | null;
  scriptUrl?: string | null;
  suggestedCaption?: string | null;
  tasks: CreateCampaignTaskInput[];
  website?: string | null;
};

type ImageFiles = {
  url: string | null;
  key: string;
  file: File | undefined;
};

type CampaignDetailsModalProps = {
  record: GetCampaignsV2_getCampaigns_entities | null;
  onFinish: () => void;
};

const CampaignsEditModal = ({
  record,
  onFinish,
}: CampaignDetailsModalProps): JSX.Element | null => {
  const [form] = Form.useForm();

  const socialActivity = record?.activities?.find(
    (item) => item.__typename === 'SocialActivity'
  ) as GetCampaignsV2_getCampaigns_entities_activities_SocialActivity;

  const [selectedTasks, setSelectedTasks] = useState<CampaignTaskType[]>(
    socialActivity?.tasks.map((item) => {
      return item.type;
    })
  );

  const [isInstagram, setIsInstagram] = useState<boolean>(
    !selectedTasks.some((item) => {
      return item === CampaignTaskType.TT_VIDEO;
    })
  );

  const [imageFiles, setImageFiles] = useState<ImageFiles[]>([
    ...socialActivity?.files.map((item) => {
      return { url: item.url, key: item.key, file: undefined };
    }),
  ]);

  const [isPhysicalProduct, setIsPhysicalProduct] = useState<boolean>(
    socialActivity?.physicalProduct || false
  );

  const [selectedLocations, setSelectedLocations] = useState<
    CampaignLocationInput[]
  >([]);

  const [isUploading, setIsUploading] = useState<boolean>(false);

  const filters = record?.search as GetCampaignsV2_getCampaigns_entities_search;
  const locations = filters?.locations;

  const exampleImages: UploadImage[] = [];
  imageFiles?.forEach((image) => {
    exampleImages.push({
      data_key: image.key,
      data_url: image.url || undefined,
    });
  });

  const { data: sportsData } = useGetSports({
    variables: {
      storeRoles: [UserRole.Athlete, UserRole.ContentCreator],
    },
  });

  const { data: hashtagsData } = useQuery<GetHashtags, GetHashtagsVariables>(
    GET_HASHTAGS,
    {
      variables: {
        keyword: '',
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  const { data: countriesList } = useQuery<GetCountryList>(GET_COUNTRY_LIST, {
    fetchPolicy: 'cache-and-network',
  });

  const [searchStores, { data: storeData, loading: loadingStoreData }] =
    useLazyQuery<SearchCampaignStores, SearchCampaignStoresVariables>(
      SEARCH_CAMPAIGN_STORES
    );

  const [updateCampaign, { loading: loadingUpdateCampaign }] = useMutation<
    UpdateCampaign,
    UpdateCampaignVariables
  >(UPDATE_CAMPAIGN);

  const [
    createCampaignExampleFiles,
    { loading: loadingCreateCampaignExampleFiles },
  ] = useMutation<
    CreateCampaignExampleFiles,
    CreateCampaignExampleFilesVariables
  >(CREATE_CAMPAIGN_EXAMPLE_FILES);

  const sports = sportsData?.sports || [];

  useEffect(() => {
    const initialLocations = locations.map((location) => {
      return {
        country: location.country,
        state: location.state || '',
      };
    });

    setSelectedLocations(initialLocations);
  }, [locations]);

  const initialPromoCodes = socialActivity?.promoCodes?.join(',');
  const initialPromoURL = socialActivity?.promoUrls?.join(',');

  const initialFormValues: FormValues = {
    budget: filters?.budget || 0,
    flatFeePerStore: record?.flatFeePerStore || 0,
    genders: filters?.genders || [],
    maxAge: filters?.athleteMaxAge || null,
    minAge: filters?.athleteMinAge || null,
    smPlatforms: filters?.smPlatforms || [],
    followingFrom:
      FOLLOWING_OPTIONS_MIN.find((f) => f.value === filters.minFollowers)
        ?.value || null,
    followingTo:
      FOLLOWING_OPTIONS_MAX.find((f) => f.value === filters.maxFollowers)
        ?.value || null,
    sports: filters?.sports || [],
    avoid: socialActivity?.avoid,
    brandName: socialActivity?.brandName || '',
    dueDate: record?.dueDate ? moment(record?.dueDate) : null,
    encouraged: socialActivity?.encouraged,
    exampleFiles: socialActivity?.files || null,
    hashtags: socialActivity?.hashtags || null,
    instructions: socialActivity?.instructions || '',
    mediaType: socialActivity?.mediaType || null,
    name: record?.name || '',
    physicalProductType: socialActivity?.physicalProductType || null,
    profileMentions: socialActivity?.profileMentions || null,
    promoCodes: initialPromoCodes ?? null,
    promoUrls: initialPromoURL ?? null,
    quantityInstructions: socialActivity?.quantityInstructions || null,
    scriptUrl: socialActivity?.script || null,
    suggestedCaption: socialActivity?.suggestedCaption || null,
    tasks: socialActivity?.tasks,
    website: socialActivity?.website || null,
  };

  const handleSubmit = async () => {
    const {
      budget,
      flatFeePerStore,
      genders,
      maxAge,
      minAge,
      followingFrom,
      followingTo,
      sports,
      avoid,
      brandName,
      dueDate,
      encouraged,
      exampleFiles,
      hashtags,
      instructions,
      mediaType,
      name,
      physicalProductType,
      profileMentions,
      promoCodes,
      promoUrls,
      quantityInstructions,
      scriptUrl,
      suggestedCaption,
      website,
    } = form.getFieldsValue();

    const criteria: SearchCampaignCriteriaInput = {
      budget: Number(budget),
      flatFeePerStore: Number(flatFeePerStore),
      genders: genders || null,
      locations: selectedLocations.map((location) => {
        return {
          country: location.country ?? '',
          ...(location.state && { state: location.state }),
        };
      }),
      ...(maxAge && {
        maxAge: Number(maxAge),
      }),
      ...(minAge && {
        minAge: Number(minAge),
      }),
      smPlatforms: isInstagram
        ? [CampaignSmPlatform.IG]
        : [CampaignSmPlatform.TT],
      ...(followingFrom && {
        socialFollowersRange: {
          from: Number(followingFrom),
        },
      }),
      ...(followingTo && {
        socialFollowersRange: {
          to: Number(followingTo),
        },
      }),
      ...(followingTo &&
        followingFrom && {
          socialFollowersRange: {
            from: Number(followingFrom),
            to: Number(followingTo),
          },
        }),
      sports,
    };

    const details: CreateCampaignDetailsInput = {
      ...(avoid && { avoid }),
      brandName,
      dueDate,
      ...(encouraged && { encouraged }),
      exampleFiles,
      hashtags,
      instructions,
      mediaType: isInstagram ? mediaType : null,
      name,
      physicalProduct: isPhysicalProduct,
      physicalProductType: isPhysicalProduct ? physicalProductType : null,
      ...(profileMentions && { profileMentions }),
      ...(promoCodes && {
        promoCodes:
          promoCodes?.split(',')?.map((tag: string) => tag.trim()) ||
          promoCodes,
      }),
      ...(promoUrls && {
        promoUrls:
          promoUrls?.split(',')?.map((tag: string) => tag.trim()) || promoUrls,
      }),
      quantityInstructions,
      ...(scriptUrl && { scriptUrl }),
      ...(suggestedCaption && { suggestedCaption }),
      tasks: selectedTasks.map((task) => {
        return { type: task };
      }),
      ...(website && { website }),
    };

    const updateCampaignInputVariables: UpdateCampaignInput = {
      id: record?.id || '',
      criteria,
      details,
    };

    const imageFilesToUpload: File[] = [];
    const uploadUrls: string[] = [];

    imageFiles.forEach((image) => {
      if (image.file) {
        imageFilesToUpload.push(image.file);
      } else if (image.key) {
        uploadUrls.push(image.key);
      }
    });

    try {
      if (imageFilesToUpload.length) {
        const { data: campaignExampleFiles } = await createCampaignExampleFiles(
          {
            variables: {
              input: {
                files: imageFilesToUpload.map((file) => ({
                  name: file.name,
                })),
              },
            },
          }
        );
        if (campaignExampleFiles?.createCampaignExampleFiles?.length) {
          setIsUploading(true);
          const urls = await uploadImages(
            campaignExampleFiles.createCampaignExampleFiles,
            imageFilesToUpload
          );
          setIsUploading(false);
          uploadUrls.push(...urls);
        }
      }

      updateCampaignInputVariables.details.exampleFiles = uploadUrls.map(
        (url) => ({ key: url })
      );

      const { data } = await updateCampaign({
        variables: {
          input: updateCampaignInputVariables,
        },
      });
      if (data?.updateCampaign) {
        successNotification('The campaign was successfully updated');
        onFinish();
      }
    } catch (error) {
      errorNotification((error as Error)?.message);
      console.error('Campaign update error:', { error });
    }
  };

  const handleChangeSocialNetworkType = () => {
    if (isInstagram) {
      setIsInstagram(false);
      setSelectedTasks([CampaignTaskType.TT_VIDEO]);
      form.setFieldsValue({ mediaType: [] });
    } else {
      setIsInstagram(true);
      setSelectedTasks([]);
    }
  };

  const handleChange = (taskType: CampaignTaskType) => {
    if (selectedTasks.includes(taskType)) {
      // Remove the task from the selectedTasks array
      setSelectedTasks(selectedTasks.filter((t) => t !== taskType));
    } else {
      // Add the task to the selectedTasks array
      setSelectedTasks([...selectedTasks, taskType]);
    }
  };

  const handleSetImages = (incomingData: UploadImage[]) => {
    const newImages: ImageFiles[] = [];

    incomingData.forEach((image) => {
      newImages.push({
        key: image.data_key || '',
        url: image.data_url || null,
        file: image.file || undefined,
      });
    });

    setImageFiles(newImages);
  };

  const handleIsPhysicalProductChange = () => {
    if (isPhysicalProduct) {
      form.setFieldsValue({
        physicalProductType: null,
      });
    }

    setIsPhysicalProduct(!isPhysicalProduct);
  };

  const getStateList = (selectedCountry: string) => {
    const countryData = countriesList?.getCountryList.find((country) => {
      return (
        country.code === selectedCountry || country.name === selectedCountry
      );
    });
    return countryData?.states;
  };

  const handleCountryChange = (country: string, index: number) => {
    const copyLocations = [...selectedLocations];

    if (isUsOrCa(country)) {
      copyLocations[index] = { country, state: '' };
    } else {
      copyLocations[index] = { country };
    }
    setSelectedLocations(copyLocations);
  };

  const handleStateChange = (state: string, index: number) => {
    const copyLocations = [...selectedLocations];

    const country = copyLocations[index].country;

    copyLocations[index] = { country, state };

    setSelectedLocations(copyLocations);
  };

  const handleDeleteLocation = (index: number) => {
    setSelectedLocations(
      selectedLocations.filter((_, counter) => counter !== index)
    );
  };

  const handleAddLocation = () => {
    setSelectedLocations([...selectedLocations, { country: '' }]);
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // allow days 2 weeks from today
    return current && current < moment().add('2', 'weeks');
  };

  const handleFilterSearch = async (criteria: SearchCampaignCriteriaInput) => {
    try {
      searchStores({
        variables: {
          input: {
            limit: 4,
            offset: 0,
            criteria,
          },
        },
      });
    } catch (error) {
      errorNotification((error as Error)?.message);
      console.error('Campaign filter error:', { error });
    }
  };

  const handleFilterClick = async () => {
    const {
      budget,
      flatFeePerStore,
      genders,
      maxAge,
      minAge,
      followingFrom,
      followingTo,
      sports,
    } = form.getFieldsValue();

    const criteria: SearchCampaignCriteriaInput = {
      budget: Number(budget),
      flatFeePerStore: Number(flatFeePerStore),
      genders: genders || null,
      locations: selectedLocations.map((location) => {
        return {
          country: location.country ?? '',
          ...(location.state && { state: location.state }),
        };
      }),
      ...(maxAge && {
        maxAge: Number(maxAge),
      }),
      ...(minAge && {
        minAge: Number(minAge),
      }),
      smPlatforms: [
        isInstagram ? CampaignSmPlatform.IG : CampaignSmPlatform.TT,
      ],
      ...(followingFrom && {
        socialFollowersRange: {
          from: Number(followingFrom),
        },
      }),
      ...(followingTo && {
        socialFollowersRange: {
          to: Number(followingTo),
        },
      }),
      ...(followingTo &&
        followingFrom && {
          socialFollowersRange: {
            from: Number(followingFrom),
            to: Number(followingTo),
          },
        }),
      sports,
    };

    handleFilterSearch(criteria);
  };

  const talentsData = storeData?.searchCampaignStores?.entities;

  const hasMore =
    (talentsData?.length ?? 0) < (storeData?.searchCampaignStores.total ?? 0);

  const showTalentList =
    !!talentsData?.length && talentsData?.length > 0 && !loadingStoreData;

  const totalFollowers = Number(
    storeData?.searchCampaignStores.summary.followers
  );
  const totalTalents = Number(storeData?.searchCampaignStores.total);

  useEffect(() => {
    // Used to call the filters API and display the initial talents
    const {
      budget,
      flatFeePerStore,
      genders,
      maxAge,
      minAge,
      smPlatforms,
      followingFrom,
      followingTo,
      sports,
    } = initialFormValues;

    const criteria: SearchCampaignCriteriaInput = {
      budget,
      flatFeePerStore,
      genders,
      locations: filters?.locations.map((location) => {
        return {
          country: location.country,
          ...(location.state && { state: location.state || '' }),
        };
      }),
      ...(maxAge && {
        maxAge,
      }),
      ...(minAge && {
        minAge,
      }),
      smPlatforms,
      ...(followingFrom && {
        socialFollowersRange: {
          from: Number(followingFrom),
        },
      }),
      ...(followingTo && {
        socialFollowersRange: {
          to: Number(followingTo),
        },
      }),
      ...(followingTo &&
        followingFrom && {
          socialFollowersRange: {
            from: Number(followingFrom),
            to: Number(followingTo),
          },
        }),
      sports,
    };

    handleFilterSearch(criteria);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitLoading =
    isUploading ||
    loadingUpdateCampaign ||
    loadingCreateCampaignExampleFiles ||
    loadingStoreData;

  const hashtagsOptions = hashtagsData?.getHashtags.map((item) => (
    <Option key={item.id} value={item.name}>
      {item.name}
    </Option>
  ));

  if (!record) return null;

  return (
    <div className={styles.root}>
      <Form
        name="EditCampaignForm"
        aria-label="Update Social Campaign"
        onFinish={handleSubmit}
        form={form}
        initialValues={initialFormValues}
      >
        <div className={styles.heading}>Campaign Criteria(filters):</div>
        <div className={styles.columnWrapper}>
          <div className={styles.switchWrapper}>
            <Text>Choose Platform (Instagram/TikTok):</Text>

            <Switch
              checked={isInstagram}
              checkedChildren="Instagram"
              unCheckedChildren="TikTok"
              onChange={handleChangeSocialNetworkType}
            />
          </div>
          <div className={styles.rowWrapper}>
            <Form.Item
              name="budget"
              rules={[
                {
                  required: true,
                  message: 'Budget is required',
                },
                {
                  validator: (_, value) => {
                    if (value && value < 2500) {
                      return Promise.reject(
                        new Error('Minimum campaign budget is $2500 USD.')
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              label={<Text>Budget</Text>}
            >
              <Input type="number" placeholder="Budget" aria-label="budget" />
            </Form.Item>
            <Form.Item
              name="flatFeePerStore"
              rules={[
                {
                  required: true,
                  message: 'Price per talent is required',
                },
              ]}
              label={<Text>Price per Talent</Text>}
            >
              <Input
                type="number"
                placeholder="Price per talent"
                aria-label="Price per talent"
              />
            </Form.Item>
          </div>
          <div className={styles.rowWrapper}>
            <Form.Item
              name="sports"
              label="Sport:"
              rules={[
                {
                  required: true,
                  message: 'Sport is required',
                },
              ]}
            >
              <Select
                id="sport"
                placeholder="Select sport type"
                mode="multiple"
                filterOption={(inputValue, option) =>
                  option?.props.children
                    .toString()
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              >
                {sports.map((item) => {
                  return (
                    <Option value={`${item.name}`} key={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name="genders" label={<Text>Gender</Text>}>
              <Select
                id="genders"
                placeholder="Select gender"
                filterOption={(inputValue, option) =>
                  option?.props.children
                    .toString()
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              >
                {GENDER_OPTIONS.map((item) => {
                  return (
                    <Option value={`${item.value}`} key={item.value}>
                      {item.label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
          <div className={styles.rowWrapper}>
            <Form.Item name="minAge" label={<Text>Minimum Age</Text>}>
              <Input
                type="number"
                placeholder="Minimum Age"
                aria-label="Minimum Age"
              />
            </Form.Item>
            <Form.Item name="maxAge" label={<Text>Maximum Age</Text>}>
              <Input
                type="number"
                placeholder="Maximum Age"
                aria-label="Maximum Age"
              />
            </Form.Item>
          </div>

          <div className={styles.rowWrapper}>
            <Form.Item name="followingFrom" label="Social following min:">
              <Select
                id="followingFrom"
                placeholder="Select"
                allowClear
                filterOption={(inputValue, option) =>
                  option?.props.children
                    .toString()
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              >
                {FOLLOWING_OPTIONS_MIN.map((item) => {
                  return (
                    <Option value={`${item.value}`} key={item.label}>
                      {item.label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name="followingTo" label="Social following max:">
              <Select
                id="followingTo"
                placeholder="Select"
                allowClear
                filterOption={(inputValue, option) =>
                  option?.props.children
                    .toString()
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              >
                {FOLLOWING_OPTIONS_MAX.map((item) => {
                  return (
                    <Option value={`${item.value}`} key={item.label}>
                      {item.label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>

          <div className={styles.locationsWrapper}>
            <div className={styles.locationsHeading}>Locations:</div>

            {selectedLocations.map((location, index) => (
              <div key={index} className={styles.location}>
                <Form.Item
                  key={index}
                  rules={[
                    {
                      required: true,
                      message: 'Country is required',
                    },
                  ]}
                  className={styles.location}
                >
                  <Select
                    value={location.country || null}
                    onChange={(e) => handleCountryChange(e, index)}
                    placeholder="Select Country"
                  >
                    {countriesList?.getCountryList.map((country) => (
                      <Option key={country.code} value={country.code}>
                        {country.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                {isUsOrCa(location.country) ? (
                  <Select
                    value={location.state || null}
                    onChange={(e) => handleStateChange(e, index)}
                    placeholder="Select State"
                  >
                    {getStateList(location.country)?.map((state) => {
                      return (
                        <Option key={state.code} value={state.code}>
                          {state.name}
                        </Option>
                      );
                    })}
                  </Select>
                ) : null}
                <Button
                  className={styles.locationButton}
                  type="primary"
                  danger
                  onClick={() => handleDeleteLocation(index)}
                >
                  Delete Location
                </Button>
              </div>
            ))}
            <Button
              className={styles.locationButton}
              type="primary"
              onClick={handleAddLocation}
            >
              Add Another Location
            </Button>
          </div>

          <div className={styles.filterButtonWrapper}>
            <Button
              type="primary"
              name="filterButton"
              loading={loadingStoreData}
              onClick={handleFilterClick}
            >
              Refresh List
            </Button>
          </div>

          {showTalentList ? (
            <TalentList
              talents={talentsData || []}
              hasMore={hasMore}
              totalTalents={totalTalents}
              totalFollowers={totalFollowers}
            />
          ) : !loadingStoreData ? (
            <div className={styles.noItems}>
              No talents found with the set criteria
            </div>
          ) : null}

          <div className={styles.campaignHeading}>Campaign Details:</div>

          <div className={styles.rowWrapper}>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
              label={<Text>Campaign name</Text>}
            >
              <Input
                type="name"
                placeholder="Enter Campaign name"
                aria-label="Campaign name"
              />
            </Form.Item>

            <Form.Item
              name="dueDate"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
                {
                  validator: (_, value) => {
                    if (value && moment().add(2, 'weeks').isAfter(value)) {
                      return Promise.reject(
                        new Error(
                          'Due date must be at least 2 weeks in the future.'
                        )
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              label={<Text>Application deadline date</Text>}
            >
              <DatePicker
                name="dueDate"
                format={onlyDateTimeFormat}
                disabledDate={disabledDate}
              />
            </Form.Item>
          </div>

          <div>
            <Form.Item
              name="instructions"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
              label={<Text>Campaign instructions</Text>}
            >
              <TextArea
                autoSize={{ minRows: 3 }}
                name="instructions"
                placeholder="Quick outline of key messaging and conversation points. What content do we want to capture to make the stream a hit."
              />
            </Form.Item>
          </div>

          <div className={styles.heading}>Company Details:</div>

          <div className={styles.rowWrapper}>
            <Form.Item
              name="brandName"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
              label={<Text>Company Name</Text>}
            >
              <Input
                type="text"
                placeholder="Enter company name"
                aria-label="Enter company name"
              />
            </Form.Item>
            <Form.Item name="website" label={<Text>Company Website</Text>}>
              <Input
                type="text"
                placeholder="https://example.com"
                aria-label="https://example.com"
              />
            </Form.Item>
          </div>

          <div className={styles.rowWrapper}>
            <Form.Item
              name="physicalProduct"
              label={<Text>Physical Products</Text>}
              valuePropName="checked"
              initialValue={isPhysicalProduct}
            >
              <Checkbox
                value={isPhysicalProduct}
                onChange={handleIsPhysicalProductChange}
              />
            </Form.Item>
            <Form.Item
              name="physicalProductType"
              rules={[
                {
                  required: isPhysicalProduct,
                  message: 'This field is required',
                },
              ]}
              label={<Text>Product Shipping Category</Text>}
            >
              <Select
                id="physicalProductType"
                placeholder="Select"
                disabled={!isPhysicalProduct}
                filterOption={(inputValue, option) =>
                  option?.props.children
                    .toString()
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              >
                {PRODUCT_TYPES.map((item) => {
                  return (
                    <Option value={`${item.value}`} key={item.label}>
                      {item.label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>

          <div className={styles.heading}>Post Details:</div>

          <div className={styles.rowWrapper}>
            <Form.Item
              name="profileMentions"
              label={<Text>Profiles to tag</Text>}
            >
              <Input
                type="text"
                placeholder="Put the usernames of the accounts you want tagged"
                aria-label="Put the usernames of the accounts you want tagged"
              />
            </Form.Item>

            {isInstagram && (
              <Form.Item
                name="mediaType"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
                label={<Text>Type Of Media</Text>}
              >
                <Select
                  id="mediaType"
                  placeholder="Select"
                  mode="multiple"
                  filterOption={(inputValue, option) =>
                    option?.props.children
                      .toString()
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  }
                >
                  {MEDIA_TYPES.map((item) => {
                    return (
                      <Option value={`${item.value}`} key={item.label}>
                        {item.label}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            )}
          </div>

          <div className={styles.rowWrapper}>
            <Form.Item
              name="promoCodes"
              label={<Text>Promo Code (Optional)</Text>}
            >
              <Input
                type="text"
                placeholder="Promo codes for talents to use"
                aria-label="Promo codes for talents to use"
              />
            </Form.Item>

            <Form.Item
              name="promoUrls"
              label={<Text>Promo URL (Optional)</Text>}
            >
              <Input
                type="text"
                placeholder="Link for talents to use"
                aria-label="Link for talents to use"
              />
            </Form.Item>
          </div>

          {isInstagram && (
            <div className={styles.instagramPost}>
              <Text>Post Type:</Text>
              <div className={styles.columnWrapper}>
                <div className={styles.instagramTypesWrapper}>
                  <Checkbox
                    checked={selectedTasks.includes(CampaignTaskType.IG_POST)}
                    onChange={() => handleChange(CampaignTaskType.IG_POST)}
                  >
                    Post
                  </Checkbox>
                  <Checkbox
                    checked={selectedTasks.includes(CampaignTaskType.IG_STORY)}
                    onChange={() => handleChange(CampaignTaskType.IG_STORY)}
                  >
                    Story
                  </Checkbox>
                  <Checkbox
                    checked={selectedTasks.includes(CampaignTaskType.IG_REEL)}
                    onChange={() => handleChange(CampaignTaskType.IG_REEL)}
                  >
                    Reel
                  </Checkbox>
                  <Checkbox
                    checked={selectedTasks.includes(CampaignTaskType.IG_LIVE)}
                    onChange={() => handleChange(CampaignTaskType.IG_LIVE)}
                  >
                    Live
                  </Checkbox>
                </div>

                <Form.Item
                  name="quantityInstructions"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                  label={
                    <Text>
                      Post quantity (How many per item selected (Posts / Reels /
                      Stories)?)
                    </Text>
                  }
                >
                  <Input
                    type="quantityInstructions"
                    placeholder="For example: 1 Reel, 1 Story"
                    aria-label="For example: 1 Reel, 1 Story"
                  />
                </Form.Item>
              </div>
            </div>
          )}

          <div className={styles.rowWrapper}>
            <Form.Item name="hashtags" label="List of hashtags">
              <Select
                placeholder="Select hashtags"
                mode="multiple"
                filterOption={(inputValue, option) =>
                  option?.props.children
                    .toString()
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              >
                {hashtagsOptions}
              </Select>
            </Form.Item>
          </div>

          <div className={styles.columnWrapper}>
            <Text>
              Suggested Post Caption (We recommend that you suggest captions but
              let talent have creative freedom to edit to match their personal
              voice):
            </Text>
            <Form.Item name="suggestedCaption">
              <TextArea
                autoSize={{ minRows: 3 }}
                name="suggestedCaption"
                placeholder="Enter instructions"
              />
            </Form.Item>
          </div>

          <div className={styles.columnWrapper}>
            <Text>I would like the talent to do:</Text>
            <Form.Item name="encouraged">
              <TextArea
                autoSize={{ minRows: 3 }}
                name="encouraged"
                placeholder="Provide suggestions, encouragement, or instructions for the talent on what you’d like them to DO."
              />
            </Form.Item>
          </div>

          <div className={styles.columnWrapper}>
            <Text>I don’t want the talent to do:</Text>
            <Form.Item name="avoid">
              <TextArea
                autoSize={{ minRows: 3 }}
                name="avoid"
                placeholder="Provide instructions for the talent on what you DON’T want them to do."
              />
            </Form.Item>
          </div>

          <div className={styles.columnWrapper}>
            <Text>Script (Optional):</Text>
            <Form.Item name="scriptUrl">
              <TextArea
                autoSize={{ minRows: 3 }}
                name="scriptUrl"
                placeholder="Suggest what you'd like them to say"
              />
            </Form.Item>
          </div>
          <div className={styles.rowWrapper}>
            <UploadMultipleImages
              images={exampleImages}
              setImages={handleSetImages}
              isMainImage={null}
            />
          </div>
        </div>
        <div className={styles.submitButtonWrapper}>
          <Button type="primary" htmlType="submit" loading={submitLoading}>
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default CampaignsEditModal;
