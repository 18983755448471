import React from 'react';
import dayjs from 'dayjs';
import env from 'api/env';
import { Button } from 'antd';
import cn from 'classnames';
// Types
import { SearchCampaignStores_searchCampaignStores_entities } from 'api/campaignV2/types/SearchCampaignStores';
import { GetBookMeStores_adminStores_entities } from 'api/store/types/GetBookMeStores';
import { GetStore_store } from 'api/store/types/GetStore';
// Helpers
import { formatAudienceSize } from 'helpers/campaignEditModal';
import { getEnvLink } from 'helpers/getEnvLink';
// Styles
import styles from './TalentItem.module.scss';

type TalentItemProps = {
  talent:
    | SearchCampaignStores_searchCampaignStores_entities
    | GetBookMeStores_adminStores_entities
    | GetStore_store;
  isBookMeTalent?: boolean;
};

const TalentItem = ({
  talent,
  isBookMeTalent = false,
}: TalentItemProps): JSX.Element => {
  const talentName =
    talent.storeDetails?.storeName ?? `${talent.firstName} ${talent.lastName}`;
  const talentUrl = `${getEnvLink(env.REACT_APP_ENV)}/${talent?.slug}`;

  const handleViewProfile = () => {
    window.open(`${talentUrl}`, '_blank');
  };

  return (
    <div className={cn(styles.root, { [styles.bookMeRoot]: isBookMeTalent })}>
      <div
        className={cn(styles.imageWrapper, {
          [styles.bookMeImageWrapper]: isBookMeTalent,
        })}
      >
        <img
          title={talentName}
          alt={talentName}
          src={talent.storeDetails?.avatarURL ?? ''}
        />
      </div>
      <div
        className={cn({
          [styles.bookMeDataWrapper]: isBookMeTalent,
        })}
      >
        <div className={styles.detailsWrapper}>
          <div
            className={cn(styles.sportsWrapper, {
              [styles.bookMeNameSportsWrapper]: isBookMeTalent,
            })}
          >
            {talent.sports?.map((sport) => (
              <span key={sport.id} className={styles.sport}>
                {sport.name}
              </span>
            ))}
          </div>

          <div className={styles.talentName}>{talentName}</div>

          <div className={styles.detailsSection}>
            <div className={styles.row}>
              <span>Location:</span>
              <span>{talent.storeDetails?.country}</span>
            </div>

            <div className={styles.row}>
              <span>Age:</span>
              <span>
                {talent.storeDetails?.birthDate
                  ? dayjs().diff(dayjs(talent.storeDetails?.birthDate), 'year')
                  : 'N/a'}
              </span>
            </div>

            <div className={styles.row}>
              <span>Gender:</span>
              <span>{talent.storeDetails?.gender}</span>
            </div>

            <div className={styles.row}>
              <span>Followers:</span>
              <span>
                {formatAudienceSize(
                  talent.storeDetails?.socialFollowing?.toString() ?? '',
                  3
                )}
              </span>
            </div>
          </div>
        </div>
        {isBookMeTalent && (
          <div className={styles.viewProfileButtonWrapper}>
            <Button
              className={styles.viewProfileButton}
              onClick={handleViewProfile}
            >
              VIEW PROFILE
            </Button>
          </div>
        )}
      </div>

      {!isBookMeTalent && (
        <Button onClick={handleViewProfile}>VIEW PROFILE</Button>
      )}
    </div>
  );
};

export default TalentItem;
